import { createRouter, createWebHistory } from 'vue-router';
import Home from '../view/index.vue';
// 直播模块
import LiveMain from '../view/liveBroadcast/liveMain.vue';
import LiveBroadcast from '../view/liveBroadcast/index.vue';
import LiveBroadcastDetail from '../view/liveBroadcast/detail.vue';
import LiveBroadcastPreview from '../view/liveBroadcast/preview.vue';
import LivePause from '../view/liveBroadcast/LivePause.vue';
import Playback from '../view/playback/index.vue';
import PlaybackDetail from '../view/playback/detail.vue';

// 视频模块
import VideoCenter from '../view/videoCenter/index.vue';
import VideoDetail from '../view/videoCenter/detail.vue';

// 个人中心模块
import PersonalCenter from '../view/personalCenter/index.vue';
import Debut from '../view/personalCenter/debut.vue';
import MyBrowseLives from '../view/personalCenter/myBrowseLives.vue';
import MyBrowseVideos from '../view/personalCenter/myBrowseVideos.vue';
import MyCommentedLives from '../view/personalCenter/myCommentedLives.vue';
import MyMessages from '../view/personalCenter/myMessages.vue';

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home',
        meta: { title: '直播互动' },
        redirect: '/liveCenter',
        children: [
            {
                path: '/liveCenter',
                component: LiveMain,
                name: 'LiveMain',
                meta: { title: '直播互动', name: 'LiveMain', alive: true  }
            },
            {
                path: '/videoCenter',
                component: VideoCenter,
                name: 'VideoCenter',
                meta: { title: '视频中心', name: 'VideoCenter', alive: true }
            },
            {
                path: '/personalCenter',
                component: PersonalCenter,
                name: 'PersonalCenter',
                meta: { title: '个人中心', name: 'PersonalCenter' },
                redirect: '/personalCenter/debut',
                children: [
                    {
                        path: '/personalCenter/debut',
                        component: Debut,
                        name: 'Debut',
                        meta: { title: '我要开播', name: 'PersonalCenter' }
                    },
                    {
                        path: '/personalCenter/myBrowseLives',
                        component: MyBrowseLives,
                        name: 'MyBrowseLives',
                        meta: { title: '我浏览的直播', name: 'PersonalCenter' }
                    },
                    {
                        path: '/personalCenter/myBrowseVideos',
                        component: MyBrowseVideos,
                        name: 'MyBrowseVideos',
                        meta: { title: '我浏览的视频', name: 'PersonalCenter' }
                    },
                    {
                        path: '/personalCenter/myCommentedLives',
                        component: MyCommentedLives,
                        name: 'MyCommentedLives',
                        meta: { title: '我评论的视频', name: 'PersonalCenter' }
                    },
                    {
                        path: '/personalCenter/myMessages',
                        component: MyMessages,
                        name: 'MyMessages',
                        meta: { title: '我的消息', name: 'PersonalCenter' }
                    }
                ]
            },
        ]
    },
    {
        path: '/liveBroadcast',
        component: LiveBroadcast,
        name: 'LiveBroadcast',
        meta: { title: '直播列表' }
    },
    {
        path: '/liveBroadcastDetail',
        component: LiveBroadcastDetail,
        name: 'LiveBroadcastDetail',
        meta: { title: '直播详情' }
    },
    {
        path: '/liveBroadcastPreview',
        component: LiveBroadcastPreview,
        name: 'LiveBroadcastPreview',
        meta: { title: '直播预告' }
    },
    {
        path: '/livePause',
        component: LivePause,
        name: 'LivePause',
        meta: { title: '直播详情' }
    },
    {
        path: '/playback',
        component: Playback,
        name: 'Playback',
        meta: { title: '直播回放' }
    },
    {
        path: '/playbackDetail',
        component: PlaybackDetail,
        name: 'PlaybackDetail',
        meta: { title: '回放详情' }
    },
    {
        path: '/videoCenter/videoDetail',
        component: VideoDetail,
        name: 'VideoDetail',
        meta: { title: '视频详情', pTitle: '视频中心', path: '/videoCenter' }
    }
]

const router = createRouter({
    // history:createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes
})

export default router