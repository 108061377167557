<script setup>
import {
  getLiveDetailApi,
  watchHistoryApi,
  enterWebsocketRoom,
  websocketRoomOnline
} from "../../api/live.js";
import Breadcrumb from "../../components/breadcrumb/index.vue";
import { ElMessage } from "element-plus";
import { nextTick, onMounted, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";
import LivePlayer from "@liveqing/liveplayer-v3"; // vue3
import icon_good from "@/assets/icon/icon_good.png";
import icon_emoji from "@/assets/icon/emoji.png";
import icon_link from '@/assets/icon/icon_link.png';
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
const route = useRoute();

const liveId = route.query.id;
const liveUrl = ref("");

const messages = ref([]);
const isScrollAtBottom = ref(true);
const textarea = ref("");
const chatBox = ref(null);
const currentOnlineNum = ref(0);

// 获取直播详情
const liveDetail = ref({});
const getLiveDetail = () => {
  getLiveDetailApi(liveId).then((res) => {
    if (res.code === 200) {
      liveDetail.value = res.data;
      if (res.data.cameraList) {
        liveUrl.value = res.data?.cameraList[0]?.transcodeList[0]?.urlFlv;
      }
    }
  });
};

let nick = "用户" + new Date().getTime();

const enterWebsocketRoomFun = () => {
  const params = {
    room: liveId,
    nick: nick,
    pwd: "",
  };
  enterWebsocketRoom(params).then((res) => {
    getOnlineNumber();
    sendnick();
  });
};

const sendnick = () => {
  var map = new Map();
  map.set("type", "init");
  map.set("nick", nick);
  map.set("room", liveId);
  map.set("pwd", "");
  var message = Map2Json(map);
  ws.send(message);
};

const Map2Json = (map) => {
  var str = "{";
  map.forEach(function (value, key) {
    str += '"' + key + '"' + ":" + '"' + value + '",';
  });
  str = str.substring(0, str.length - 1);
  str += "}";
  return str;
};

// 点赞
const handleLike = async () => {
  if (likeTimerCount === 0) {
    likeTimerCount = 30;
  } else {
    ElMessage.error("请不要频繁点赞");
    return;
  };
  likeTimer = setInterval(() => {
    if (likeTimerCount === 0) {
      likeTimer = null;
      clearInterval(likeTimer);
      return;
    };
    likeTimerCount--;
  }, 1000);
  textarea.value = "为主播点了个赞";
  addMessage();
  if (isScrollAtBottom.value) {
    nextTick(() => {
      textarea.value = "";
      const container = chatBox.value;
      container.scrollTop = container.scrollHeight;
    });
  };
  ElMessage.success("点赞成功");
};

// 表情
const handleEmoji = () => {
  showEmoji.value = !showEmoji.value;
};

// 创建浏览记录
const watchHistory = () => {
  const params = {
    id: liveId,
    userId: "23143214",
  };
  watchHistoryApi(params);
};

// 播放错误回调
const handleError = (e) => {
  console.log("error", e);
};

// 播放暂停回调
const handlePause = (e) => {
  console.log("pause", e);
};

// 播放停止回调
const handleEnded = (e) => {
  console.log("end: 播放停止");
};

// 通知消息回调
const handleMessage = (e) => {
  console.log("message:", e);
};

let ws = null;
let isConnect = false; // 连接标识 避免重复连接
let receiveCode = null; // 断线重连后，延迟5秒重新创建WebSocket连接，reciveCode用来存储延迟请求的代码
let timeoutObj = null; // 延时发送消息对象（启动心跳时新建这个对象，收到消息后重置这个对象）
let likeTimer = null; // 点赞定时器
let likeTimerCount = 0; // 点赞间隔时间30s

// websocket重连
const reConnect = () => {
  // 如果已经连上就不在重连了
  if (isConnect) {
    return;
  };
  ws.close();
  ws = null;
  clearTimeout(receiveCode);
  // 延迟5秒重连 避免过多次过频繁请求重连
  receiveCode = setTimeout(() => {
    newWebSocket();
  }, 5000);
};

// 获取当前在线人数
const getOnlineNumber = () => {
  const params = {
    room: liveId
  };
  websocketRoomOnline(params).then(res => {
    console.log('websocketRoomOnline:', res);
    currentOnlineNum.value = res.onlineNum;
  });
};

// 创建并初始化websocket实例
const newWebSocket = () => {
  if (window.WebSocket) {
    if (!ws) {
      // 创建WebSocket对象，并指定服务器的地址
      ws = new WebSocket(import.meta.env.VITE_TINTMCE_SOCKET_URL);
      // ws = new WebSocket("ws://60.204.234.25/prod-api/websocketChat");
    };
    // 与服务器建立连接
    ws.onopen = () => {
      nextTick(() => {
        isConnect = true;
      });
      // 连接成功是获取当前在线人数
      enterWebsocketRoomFun();
      // 14.5分钟发一次心跳，比server端设置的连接时间稍微小一点，在接近断开的情况下以通信的方式去重置连接时间。
      timeoutObj = setTimeout(() => {
        if (isConnect) {
          var map = new Map();
          map.set("type", "ping");
          var message = Map2Json(map);
          ws.send(message);
        };
      }, 870000);
    };

    // 收到服务器发送的消息
    ws.onmessage = (event) => {
      console.log('onmessage:', event);
      var data = JSON.parse(event.data);
      // 此处监听房间人数的接口
      if (data.type === 'init') {
        getOnlineNumber();
      }
      var data = JSON.parse(event.data);
      var msg = data.msg;
      var nick = data.sendUser;
      const obj = {
        name: nick,
        text: msg,
        type: "msg",
      };
      if (nick !== "系统消息") {
        messages.value.push(obj);
        if (isScrollAtBottom.value) {
          nextTick(() => {
            textarea.value = "";
            const container = chatBox.value;
            container.scrollTop = container.scrollHeight;
          });
        };
      };
      clearTimeout(timeoutObj);
    };

    // 连接或通信过程中发生错误
    ws.onerror = (event) => {
      console.log("errror: ", event.error);
      nextTick(() => {
        isConnect = false;
        // 连接错误 需要重连
        reConnect();
      });
    };

    // 与服务器断开连接
    ws.onclose = (event) => {
      console.log("close: ", event);
      nextTick(() => {
        isConnect = false;
        // 连接错误 需要重连
        reConnect();
      });
    };
  } else {
    console.log("当前浏览器不支持websocket");
  };
};

// 获取当前观看直播人数
const getPersonViews = () => {

};

// 聊天窗口滚动监听
const handleScroll = (e) => {
  const { scrollTop, scrollHeight, clientHeight } = e.target;
  if (!isScrollAtBottom.value) {
    isScrollAtBottom.value = scrollHeight - (scrollTop + clientHeight) < 1; // 判断是否滚动到底部
  };
};

// 用户发送聊天信息
const addMessage = () => {
  showEmoji.value = false;
  if (ws.readyState === ws.OPEN) {
    // ws开启状态
    if (!textarea.value) {
      ElMessage.error("评论不能为空~");
      return;
    };
    var map = new Map();
    map.set("type", "msg");
    map.set("msg", textarea.value);
    map.set("nick", nick);
    var message = Map2Json(map);
    ws.send(message);
    const obj = {
      name: nick,
      text: textarea.value,
      type: "msg",
    };
    messages.value.push(obj);
    if (isScrollAtBottom.value) {
      nextTick(() => {
        textarea.value = "";
        const container = chatBox.value;
        container.scrollTop = container.scrollHeight;
      });
    };
  } else if (ws.readyState === ws.CONNECTING) {
    // 正在开启状态，则等待1s后重新调用
    setTimeout(function () {
      addMessage();
    }, 1000);
  } else {
    // 若未开启，则等待1s后重新调用
    setTimeout(function () {
      addMessage();
    }, 1000);
  };
};

// 表情选择
const showEmoji = ref(false);
const onVue3EmojiPicker = (e) => {
  textarea.value += e.i;
};

const onFocus = () => {
  showEmoji.value = false;
};

watch(
  () => textarea.value,
  (val) => {
    const len = val.length;
    if (len >= 20) {
      textarea.value = val.substring(0, 20);
      showEmoji.value = false;
    };
  }
);

onMounted(async () => {
  getLiveDetail();
  watchHistory();
  newWebSocket();
});

onUnmounted(() => {
  if (ws) {
    ws.close();
  }
});
</script>

<template>
  <div class="page-container">
    <Breadcrumb name="直播详情"></Breadcrumb>
    <div class="page-content">
      <div class="page-main">
        <div class="main-left">
          <div class="main-left-header">
            <div class="header-title">{{ liveDetail.title || "-" }}</div>
            <div class="header-detail flexRow aICenter">
              <div class="flexRow aICenter">
                <img src="@/assets/icon/icon_eye_grey.png" alt="" />
                <span>{{ currentOnlineNum || 0 }}</span>
              </div>
              <div>主播：{{ liveDetail.anchorName || "-" }}</div>
              <div>时间：{{ liveDetail.liveTimeStart || '-' }}</div>
            </div>
          </div>
          <LivePlayer
            :videoUrl="liveUrl"
            fluent
            live
            @error="handleError"
            @pause="handlePause"
            @ended="handleEnded"
            @message="handleMessage"
          ></LivePlayer>

          <div class="main-left-desc">
            <div class="title flexRow aICenter">
              <div class="cube"></div>
              <div class="title-txt">直播详情</div>
            </div>
            <div class="desc-content">
              {{ liveDetail.details || "暂无直播详情" }}
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="main-right-chat" ref="chatBox" @scroll="handleScroll">
            <div class="main-right-chat-messages">
              <div class="main-right-chat-warn">
                系统提示：直播内容及互动评论须严格遵守直播规范，严禁传播违法违规、低俗血暴、吸烟酗酒、造谣诈骗等不良有害信息。如有违规，平台将对违规直播间或账号进行相应的处罚！
              </div>
              <div
                class="main-right-chat-message"
                v-for="(item, index) in messages"
                :key="index"
              >
                <template v-if="item.type === 'like'">{{ item.name + "点赞了主播~" }}</template>
                <template v-else>{{ item.name + ": " + item.text }}</template>
              </div>
            </div>
          </div>
          <div class="main-right-mask" v-if="!isConnect">
            <div class="main-right-mask-tips">
              <span>聊天窗口连接中</span>
              <img :src="icon_link">
            </div>
          </div>
          <el-input
            v-model="textarea"
            maxlength="20"
            style="width: 100%"
            show-word-limit
            type="textarea"
            :rows="3"
            placeholder="发个评论呗~"
            @keyup.enter="addMessage"
            @focus="onFocus"
            resize="none"
            :disabled="!isConnect"
          />
          <div class="main-right-operate flexRow jCBetween">
            <div class="flexRow">
              <div class="like-btn flexRow aICenter" @click="handleLike">
                <img :src="icon_good" />
                <span>点赞</span>
              </div>
              <div class="like-btn flexRow aICenter" @click="handleEmoji">
                <img :src="icon_emoji" alt="表情" />
              </div>
              <EmojiPicker
                v-if="showEmoji"
                class="emoji-box"
                :native="true"
                size="big"
                :recent="true"
                :manualClose="true"
                :hide-search="true"
                @select="onVue3EmojiPicker"
              />
            </div>
            <div class="send-btn" @click="addMessage">发送</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
  max-width: 1920px;
  background: #f5f5f7;
  min-height: 870px;
  .breadcrumb {
    width: 100%;
    margin: 0 auto;
    height: 37px;
    max-width: 1920px;
    background: #fff;
    display: flex;
    align-items: center;
    .breadcrumb_content {
      width: 1200px;
      margin: 0 auto;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      span {
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
      }
      .arrow {
        width: 6px;
        height: 9px;
        margin: 0 10px;
      }
    }
  }
  .page-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    box-sizing: border-box;
    .page-main {
      width: 100%;
      height: 503px;
      display: flex;
      margin-bottom: 60px;
      .main-left {
        width: 895px;
        height: 100%;
        position: relative;
        margin-right: 20px;
        .main-left-header {
          width: 100%;
          height: 92px;
          background-color: #fff;
          padding: 20px 20px 0;
          .header-title {
            font-weight: 600;
            font-size: 20px;
            color: #1f1f1f;
            line-height: 23px;
            margin-bottom: 4px;
          }
          .header-detail {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            > div {
              margin-right: 20px;
              margin-top: 4px;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
        video {
          width: 100%;
          height: 100%;
        }
        .main-left-desc {
          padding: 20px 20px 30px;
          background-color: #fff;
          .title-txt {
            margin-left: 10px;
          }
          .desc-content {
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            color: #1f1f1f;
            line-height: 16px;
          }
        }
      }
      .main-right {
        flex: 1;
        height: 595px;
        background: #fff;
        padding: 20px 15px;
        box-sizing: border-box;
        position: relative;
        .main-right-chat {
          width: 100%;
          height: 432px;
          background: #f2f2f2;
          padding: 10px;
          overflow-y: scroll;
          margin-bottom: 15px;
          .main-right-chat-warn {
            font-weight: 400;
            font-size: 12px;
            color: #fa9550;
            line-height: 14px;
            margin-bottom: 10px;
          }
          .main-right-chat-message {
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 14px;
            margin-bottom: 8px;
          }
        }
        .main-right-chat::-webkit-scrollbar {
          width: 0;
          display: none;
        }
        .main-right-mask {
          width: 100%;
          height: 100%;
          position: absolute;
          background: rgba(0, 0, 0, 0.6);
          top: 0;
          left: 0;
          z-index: 999;
          display: flex;
          align-items: center;
          justify-content: center;
          .main-right-mask-tips {
            width: 180px;
            padding: 10px 20px;
            color: #fff;
            background: rgba(255, 255, 255, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            // flex-direction: column;
            border-radius: 8px;
            font-size: 14px;
            img {
              width: 14px;
              height: 14px;
              margin-left: 10px;
              animation: rotate 2s linear infinite;
            }
            @keyframes rotate {
              0% {
                transform: rotate(0);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
        .main-right-operate {
          margin-top: 10px;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 14px;
          position: relative;
          .like-btn {
            cursor: pointer;
            margin-right: 12px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .emoji-box {
            position: absolute;
            z-index: 99;
            width: 400px;
            top: -410px;
            left: -70px;
          }
          .send-btn {
            width: 78px;
            height: 25px;
            background: #1890ff;
            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
