<script setup>
  import Breadcrumb from '../../components/breadcrumb/index.vue';
  import { getPlaybackDetailApi } from '../../api/live.js';
  import { useRoute } from 'vue-router';
  import LivePlayer from "@liveqing/liveplayer-v3"; // vue3
  const route = useRoute();

  const id = ref('');
  const detailInfo = ref({});
  
  // 获取回放详情
  const getPlaybackDetail = () => {
    getPlaybackDetailApi(id.value).then(res => {
      if (res.code === 200) {
        console.log(res.data);
        let data = res.data;
        data.livePictureAddress = JSON.parse(data.livePictureAddress)[0];
        detailInfo.value = data;
      }
    })
  };

  onMounted(() => {
    id.value = route.query.id;
    getPlaybackDetail();
  })
</script>

<template>
  <div class="page-container">
    <Breadcrumb name="回放详情"></Breadcrumb>
    <div class="page-content">
      <div class="page-main">
        <div class="header">
          <div class="header-title flexRow aICenter">
            {{ detailInfo.title }}
            <el-tag type="info" effect="plain" size="small" style="margin-left: 10px;">回放中</el-tag>
          </div>
          <div class="header-detail flexRow aICenter">
            <div class="flexRow aICenter">
              <img src="@/assets/icon/icon_eye_grey.png" alt="" />
              <span>{{ detailInfo.views || 0 }}</span>
            </div>
            <div>主播：{{ detailInfo.anchorName }}</div>
            <div>时间：{{ detailInfo.transcribeComplete }}</div>
          </div>
        </div>
        <div class="center">
          <LivePlayer
            :videoUrl="detailInfo.recordUrl"
            fluent
            @error="handleError"
            @pause="handlePause"
            @ended="handleEnded"
            @message="handleMessage"
          ></LivePlayer>
          <!-- <video 
            src="http://mgcdn.vod.migucloud.com/vi1/800000717.21jtN6FYRca8nQCWi4EnQY.source.YryLJT/mm.m3u8"
            controls
          >
          </video> -->
        </div>
        <div class="bottom">
          <div class="title flexRow aICenter">
            <div class="cube"></div>
            <h4>直播详情</h4>
          </div>
          <div class="detail">{{ detailInfo.details }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-main {
  width: 895px;
  padding: 20px 0;
  background-color: #fff;
  margin: 0 auto;
  .header {
    width: 100%;
    background-color: #fff;
    padding-left: 20px;
    .header-title {
      font-weight: 600;
      font-size: 20px;
      color: #1f1f1f;
      line-height: 23px;
      margin-bottom: 4px;
    }
    .header-detail {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 16px;
      > div {
        margin-right: 20px;
        margin-top: 4px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  .center {
    margin-top: 20px;
    video {
      width: 100%;
      height: 500px;
      background-color: #eee;
    }
  }
  .bottom {
    padding-left: 20px;
    margin-top: 20px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      color: #1F1F1F;
      line-height: 19px;
      margin-left: 10px;
    }
    .detail {
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #1F1F1F;
      line-height: 16px;
    }
  }
}
</style>
